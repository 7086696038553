import { n as normalizeComponent } from "./vueComponentNormalizer.js";
var render$1 = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("create-modal-composer", {
    attrs: {
      "title": "Add Grade"
    }
  }, [_c("select-input", {
    key: "cropId",
    attrs: {
      "label": "Crop Code",
      "placeholder": "Select Crop Code",
      "reference": "grower.common.crops",
      "source": "id",
      "rules": "required",
      "source-label": _vm.genCropCodeLabel,
      "span": 12
    }
  }), _c("text-input", {
    key: "code",
    attrs: {
      "label": "Grade Code",
      "placeholder": "Type Code",
      "rules": _vm.ruleSpecialCharacter,
      "max-length": 5,
      "span": 12
    }
  }), _c("text-input", {
    key: "description",
    attrs: {
      "label": "Description",
      "placeholder": "Type Description",
      "max-length": 40,
      "span": 12
    }
  }), _c("text-input", {
    key: "sequence",
    attrs: {
      "label": "Sequence",
      "placeholder": "Type Sequence",
      "span": 12,
      "rules": "integer"
    }
  }), _c("switch-input", {
    key: "warningTolerance",
    attrs: {
      "label": "Warning Tolerance",
      "span": 8
    }
  }), _c("text-input", {
    key: "warningToleranceLimit",
    attrs: {
      "label": "Warning Tolerance Limit",
      "placeholder": "Type Warning Tolerance Limit",
      "rules": _vm.rule3Whole1Decimal,
      "max-length": 2,
      "span": 16
    }
  }), _c("text-input", {
    key: "warningText",
    attrs: {
      "label": "Warning Text",
      "placeholder": "Type Warning Text",
      "span": 24
    }
  }), _c("switch-input", {
    key: "observedTolerance",
    attrs: {
      "label": "Observed Tolerance",
      "span": 8
    }
  }), _c("text-input", {
    key: "observedToleranceLimit",
    attrs: {
      "label": "Observed Tolerance Limit",
      "placeholder": "Type Observed Tolerance Limit",
      "rules": _vm.rule3Whole1Decimal,
      "max-length": 2,
      "span": 16
    }
  }), _c("switch-input", {
    key: "downloadToJag",
    attrs: {
      "label": "Jag to Scale",
      "span": 8
    }
  }), _c("text-input", {
    key: "appliedDeduction",
    attrs: {
      "label": "Applied Deduction",
      "placeholder": "Type Applied Deduction",
      "rules": _vm.rule3Whole1Decimal,
      "span": 16
    }
  })], 1);
};
var staticRenderFns$1 = [];
const __vue2_script$1 = {
  name: "CreateGrades",
  data() {
    return {
      rule3Whole1Decimal: {
        number: true,
        decimal: {
          wholeLength: 3,
          fractionalLength: 1
        }
      },
      ruleSpecialCharacter: {
        required: true,
        regexCustom: {
          regex: /^[A-Za-z0-9 ]+$/,
          message: "must not contain special character"
        }
      }
    };
  },
  methods: {
    genCropCodeLabel(option) {
      return `${option.code} - ${option.name}`;
    }
  }
};
const __cssModules$1 = {};
var __component__$1 = /* @__PURE__ */ normalizeComponent(__vue2_script$1, render$1, staticRenderFns$1, false, __vue2_injectStyles$1, null, null, null);
function __vue2_injectStyles$1(context) {
  for (let o in __cssModules$1) {
    this[o] = __cssModules$1[o];
  }
}
var CreateGrades = /* @__PURE__ */ function() {
  return __component__$1.exports;
}();
var render = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("div", {
    staticClass: "create-grade"
  }, [_c("resource", {
    attrs: {
      "name": "grower.common.crops",
      "api-url": _vm.apiUrl
    }
  }), _c("resource", {
    attrs: {
      "name": "grower.grades",
      "api-url": _vm.apiUrl,
      "create": _vm.CreateGrades,
      "redirect-route": "/watties-grower/grades"
    }
  })], 1);
};
var staticRenderFns = [];
const apiUrl = "#{VUE_APP_API_URL}#";
const __vue2_script = {
  name: "CreateGrades",
  data() {
    return {
      CreateGrades,
      apiUrl
    };
  }
};
const __cssModules = {};
var __component__ = /* @__PURE__ */ normalizeComponent(__vue2_script, render, staticRenderFns, false, __vue2_injectStyles, null, null, null);
function __vue2_injectStyles(context) {
  for (let o in __cssModules) {
    this[o] = __cssModules[o];
  }
}
var index = /* @__PURE__ */ function() {
  return __component__.exports;
}();
export { index as default };
